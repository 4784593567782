import { getPetTypes } from '../api/index';

const usePetTypes = async () => {
    const response = await getPetTypes();
    let typePets = [];
    if (response.status !== "fail") {
        typePets = response?.data?.typePets
            ?.filter(({ value }) => value === "Dogs" || value === "Cats") // Filtrar por Dogs y Cats
            .map(({ value, name }) => ({
                value: value, label: name
            }));
    }
    return { typePets };
}

export default usePetTypes;